<template>
  <v-app>

    <global-header v-if="userLoggedIn"/>

    <v-main>
        <router-view></router-view>
        <global-eventSelectorMobile/>
    </v-main>

    <global-footer v-if="userLoggedIn"/>

  </v-app>
</template>

<script>

import {
  mapActions,
  mapState
} from 'vuex'

/* eslint-disable no-unused-vars */
export default {
  data: () => ({
    polling: null
  }),
  computed: {
    ...mapState('app', ['userLoggedIn'])
  },
  created() {

  },
  watch: {
    '$route' (to, from) {
      document.title = to.meta.title || 'Dashboard'
    },
    // only start the polling of dashboard data once the user has logged in
    userLoggedIn(newValue, oldValue) {
      if(newValue) {
        this.initialLogin().then(response => {
          console.log("Login Response", response);
          localStorage.setItem('setSelectedEventId', response.RecentlyViewed.EventId);

          let obj = response.Events.filter(element => {
            return element.EventId === response.RecentlyViewed.EventId;
          });
          
          let selectedSid = obj[0].Stallholders[0].StallholderId;
          localStorage.setItem('setSelectedStallholderId', selectedSid);

        })
        .then(() => {
          this.pollDashboardData()
        })

      } else {
        // clearInterval
        clearInterval(this.polling)
        console.log("clear interval")
      }
    }
  },
  methods: {
    ...mapActions('dashboard', ['updateDashboard']),
    ...mapActions('filter', ['initialLogin']),
    pollDashboardData() {
      // make initial call once user logs in
      this.updateDashboard("App.Vue pollDashboardData")

      if(process.env.NODE_ENV != "development") {
        // then setup the interval
        this.polling = setInterval(() => {
          this.updateDashboard("App.Vue pollDashboardData Dev")
        }, 60000)
      } else {
        // then setup the interval
        this.polling = setInterval(() => {
          this.updateDashboard("App.Vue pollDashboardData")
        }, 60000)        
      }
    }
  }
}
</script>

<style>
#app {
  /* color: blue; */
  background-color: #272b30;
}
</style>
