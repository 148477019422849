import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';

import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

import router from './router'

// Plugins
import '@/plugins'

// Components
import '@/components'

// Services
import { authentication, pdfEditor, dashboardAPI, generateColors } from '@/services'


import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);
import '@aws-amplify/ui-vue';
import store from './store'

Vue.config.productionTip = false

// Services will be available to be used globally
Vue.prototype.$authentication = authentication
Vue.prototype.$pdfEditor = pdfEditor
Vue.prototype.$dashboardAPI = dashboardAPI
Vue.prototype.$generateColors = generateColors

console.log("process.env")
console.log(process.env)

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
